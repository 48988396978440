@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300&display=swap");
/* import 'color.js'; */

body,
html {
  overflow-x: hidden;
  background: rgb(250, 250, 250);
  /* margin-right: 5px; */
}

.round_test {
  border-radius: 5px !important;
}

.round-card {
  border-radius: 7px !important;
}

.reset-button {
  color: #fff;
  background-color: #2196f3;
}

.logout-button-css:hover {
  background-color: #2196f3 !important;
  border: 2px solid #2196f3;
  color: white;
}

.logout-button-css {
  border: 2px solid black;
}

.btn-primary {
  background-color: #e65806 !important;
  border-color: #e65806 !important;
}

h1 {
  color: #172b4c !important;
}

h3,
h4 {
  color: #5f6368 !important;
}

/* .shadow{
    box-shadow: .001rem 1rem rgba(0,0,0,.15) !important; 
} */

.human_body {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  border: 2px solid black;
  border-radius: 10px;
  background: rgb(250, 250, 250);
}

.navIcon {
  line-height: 0%;
  transform: translate(-12%, 10%);
}

.bg-new3 {
  background-color: rgb(252, 245, 237);
}

.bg-new4 {
  background-color: rgb(85, 137, 252);
}
.bg-new5 {
  background-color: rgb(12, 0, 179);
}
.bg-new6 {
  background-color: rgb(201, 16, 185);
}
.bg-new7 {
  background-color: rgb(0, 0, 0);
}
.set {
  overflow: hidden;
}
.footer1 {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(212, 157, 121);
  text-align: center;
}
.footer2 {
  background-color: rgb(134, 96, 73);
  text-align: center;
  height: auto;
}
.btn-xl {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: none;
  line-height: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-right: 15px;
  margin-left: 15px;
}

.active,
.btn-xl:hover {
  box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.2),
    5px 10px 23px 0 rgba(0, 0, 0, 0.19);
}
.changecolor {
  color: white;
  display: inline;
  margin-left: 50px;
}
.navbar-collapse.collapse.show {
  display: block !important;
}

.flexcard {
  width: 50%;
  border-radius: 10px;
}

.bottom_left {
  background: rgb(222, 219, 4);
}

.th {
  width: 2vw;
  font-size: x-large;
}

.thfirst {
  width: 50%;
}

.phalf {
  padding: 1px 5px 1px 5px;
  box-shadow: 1px 1px 1px #888888;
  border-radius: 2px;
}

table {
  box-shadow: 3px 3px 5px #eee;
  border-radius: 10px;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 2px solid lightgrey;
}

table th {
  font-size: x-large;
}

.table-white-background {
  background-color: #fff !important;
}

.border-dark {
  border: 1px solid white !important;
}

.accordion {
  cursor: pointer !important;
}

#table1,
#table2,
#table3 {
  width: 25vw;
}

.tableHead {
  font-family: "Ubuntu", sans-serif;
}

.bg-success-imp {
  background: rgba(0, 200, 0, 255);
}

.bg-warning-imp {
  background: rgba(251, 183, 45, 255);
}

.bg-danger-imp {
  background: rgba(255, 145, 83, 255);
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.text-font {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 550;
}

.text-font-heading {
  font-family: "Nunito Sans", sans-serif;
  font-weight: bolder;
}

.reduce_width {
  padding: 80px !important;
  padding-bottom: 20px !important;
  /* height: 95%; */

  /* width: 50px; */
  /* height: 100%; */
}
.lipids-height {
  height: 73%;
}

.pop_up ::-webkit-scrollbar-thumb {
  background: rgb(247, 243, 243);
}

@media all and (max-width: 990px) {
  .custom-class {
    width: 100%;
    display: block;
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: rgb(252, 245, 237);
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(170, 171, 175);
}

@media all and (max-width: 766px) {
  .design img {
    width: 10vw;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  #table1,
  #table2,
  #table3 {
    width: 65vw;
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.fix_height {
  height: fit-content;
}

.img-icon {
  margin-right: 20px;
  width: 45px;
  /* margin-top: 0px;
    margin-bottom: 2px; */
}

.low-padding {
  padding-bottom: 100px;
}

.highlight {
  background-color: rgb(206, 205, 205);
}

.minimise-size {
  text-size-adjust: 50px;
}

.left-aline {
  text-align: left;
}
.aline-table {
  margin-left: 33%;
}

.upDownButton:focus {
  outline: none;
}
.upDownButton {
  padding: 2px;
  /* margin-left: 3px;
    margin-right: 3px; */
  border-style: none;
  /* background-color: rgb(252, 245, 237); */
}

.right-side {
  margin-left: 7% !important;
}

.right-side-name {
  margin-left: 20px;
  width: 25px;
  height: 25px;
}
.left-margin {
  margin-left: 10px;
}

.top-margin {
  margin-top: 100px;
}
.testing {
  background-color: red;
}

.margin-below {
  /* position: fixed; */
  /* margin-top: 100px; */
  margin-bottom: 0px;
  /* margin-top: 50px; */
}

.test {
  background-color: black;
}

.padd {
  padding-bottom: 192px;
}

.handle-insight-images {
  /* border-radius: 50%; */
  height: 48px;
  width: 47px;
  margin-left: 16%;
}

.footer_term {
  text-decoration: none !important;
  color: #04d9ff;
}
.table-margin {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.emoji {
  margin-left: 10px;
}
.text-row {
  padding-bottom: 10px;
}
.visit-link {
  margin-left: 15px;
}

.headings {
  /* display: inline-block; */
  padding: 32px;
}

.button {
  display: inline-block;
  margin-left: 215px;
  /* margin-bottom: 50px; */
  margin-top: 0px;
  /* padding-bottom: 50px; */
}

select {
  width: 90%;
}
.bold-heading {
  font-weight: bold;
}
.insights-button {
  padding: 0.675rem 0.75rem !important;
}
.download_csv_btn {
  /* display: inline-block; */
  border-radius: 10px;
}
.download_csv_btn_real {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  border-radius: 50px;
  margin-left: 150px;
}
.height-fix {
  display: inline-block;
  width: 630px;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

.horizontal_slider {
  display: block;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: pre;
  /* margin-bottom: 50px; */
  margin-top: 50px;
  padding-bottom: 30px;
}

.horizontal_slider::-webkit-scrollbar {
  display: -webkit-box;
}

.item {
  display: inline-block;
  margin-right: 50px;
  white-space: normal;
}

.insight_download_button {
  background-color: rgb(50, 194, 13);
  color: white;
  border-radius: 10px;
  /* /* padding-left: 20px;
    padding-right: 20px; */
  /* padding-top: 10px; 
    padding-bottom: 10px; */
  /* margin-left: 430px; */
}

.insights_heading_scroll_left {
  margin-left: 20px;
  margin-top: 50px;
}

.heading_profile {
  margin-bottom: 30px;
  margin-top: 40px;
  display: inline-block;
}

.insight-table-curve {
  border-radius: 10px !important;
}

.insight-i-icon {
  margin-left: 10px;
  fill: rgb(163, 155, 155);
}

.leaders-i-icon {
  fill: rgb(0, 234, 0);
}

.learners-i-icon {
  fill: rgb(251, 230, 45);
}

.laggards-i-icon {
  fill: rgb(245, 107, 27);
}

.login-card-round {
  border-radius: 4%;
}

.login-margin {
  margin-top: 16%;
  margin-bottom: 10%;
}

.login-button {
  height: 65px;
}

.background-white {
  background-color: white;
}

.test-bottom-margin {
  margin-bottom: 2%;
}

.howto-spacing {
  word-spacing: 2px;
}

.material-textfield {
  position: relative;
}

.material-textfield label {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-top-color: transparent;
  background-color: white;
  color: gray;
  padding: 0 0.1rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}
.material-textfield input {
  font-size: 1rem;
  outline: none;
  border: 1px solid rgb(187, 183, 183);
  border-radius: 5px;
  padding: 28px 13px 28px;
  color: gray;
  transition: 0.1s ease-out;
}
.material-textfield input:focus {
  border: 2px solid rgb(33, 150, 243);
}
.material-textfield input:focus + label {
  /* color: rgb(33, 150, 243); */
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
.material-textfield input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.demographics-icon-margin {
  margin-left: 5%;
}

.round {
  position: relative;
  margin-left: 20%;
}

.round label {
  background-color: #ecf0f4;
  border: 2px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.round label:after {
  border: 3px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 7px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #2196f3;
  border-color: #1d94f5;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.icon-image {
  width: 75%;
}
@media screen and (max-width: 650px) {
  .div-covers {
    display: flex;
    flex-direction: column-reverse;
  }

  .padd {
    padding-bottom: 0px;
  }

  .icon-image {
    width: 50px;
    margin-left: 10%;
  }

  .insight-table-responsive-margin {
    margin-left: -2%;
  }

  .aline-table {
    margin-left: 18%;
  }
  .test-row-margin {
    margin-right: -15% !important;
  }
}

@media screen and (max-width: 360px) {
  .insight-table-responsive-margin {
    margin-left: -2%;
  }
}

.drive-css {
  margin: 4px 15px 0px 15px;
}

.drive-css:hover {
  color: black;
  cursor: pointer;
}

.drive-links-css {
  color: black;
}

.drive-links-css:hover {
  color: dodgerblue;
  cursor: pointer;
}

.signout-css {
  color: black;
}

.signout-css:hover {
  color: white;
}

.excel-css {

}

.excel-css:hover {
  margin: 3px 0px 0px 3px; 
}

@media (max-width: 500px) {
  .nav-item-css {
    display: flex;
    justify-content: center;
  }
}

@media not all and (max-width: 500px) {
  .nav-item-css {
    margin-left: 6px;
  }
}